import React from 'react';
import { ButtonPriority, Text, TextTypography } from 'wix-ui-tpa';

import withGroupsListContext from '../../contexts/withGroupsListContext';

import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';

import { classes, st } from './Groups.st.css';

import { DATA_HOOKS } from './dataHooks';
import { withUser } from '../../../../common/context/user/withUser';

import { GroupsSectionComponent } from './GroupsSection';
import { WithAppToastsProps } from '../../../../common/components/AppToats/withAppToastsProps';

import {
  InjectedWebOutProps,
  withWebOut,
} from '../../../../common/components/WebOut';
import { MemberInvites } from '../../../../common/types/member-invites/MemberInvites';
import {
  AppToasts,
  withAppToasts,
} from '../../../../common/components/AppToats';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { compose } from '../../../../common/utils/compose';
import { withMemberInvites } from '../../../../common/context/member-invites';

import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { SearchSortingBox } from '../SearchSortingBox/SearchSortingBox';
import {
  withSettings,
  WithSettingsProps,
  withStyles,
  WithStylesProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../../common/settings/groups-list/settings';
import { stylesParams } from '../../../../common/settings/groups-list/styles';
import { groupsSearchForGroup } from '@wix/bi-logger-groups/v2';
import { ThemeProvider } from '../../../../common/context/theme';
import { IGroupsListContext } from '../../../../common/context/groups-list/IGroupsListContext';
import { IUserContext } from '../../../../common/context/user/IUserContext';
import { CreateGroupButton } from './CreateGroupButton';
import { GroupRequestsList } from 'Groups/Widget/PendingGroupList/GroupRequestsList';

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = GroupsProps &
  IGroupsListContext &
  IUserContext &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WithStylesProps &
  WithSettingsProps & { mobile: boolean } & InjectedWebOutProps &
  MemberInvites &
  InjectedExperimentsProps &
  InjectedBiLoggerProps;

export class GroupsComponent extends GroupsSectionComponent {
  static getDerivedStateFromProps(
    nextProps: ComponentProps,
    prevState: GroupsState,
  ): Partial<GroupsState> {
    const { isGroupCreating } = nextProps;
    const nextState: Partial<GroupsState> = {
      isGroupCreating,
    };
    if (isGroupCreating === false && prevState.isGroupCreating === true) {
      nextState.isCreateGroupModalOpened = false;
    }

    return nextState;
  }

  render() {
    const {
      actions,
      groupsSortBy,
      settings,
      t,
      toasts,
      mobile,
      styles,
      groupSectionUrl,
      groupUrls,
      experiments,
    } = this.props;

    const withTitle = settings.get(settingsParams.showHeaderTitle);
    const groupListTitle = settings.get(settingsParams.groupListTitle);
    const withSearchSortingBox = settings.get(settingsParams.showSearchSorting);

    const listTitleFont = styles.get(stylesParams.listTitleFont);
    const headerTag = listTitleFont.htmlTag;

    return (
      <ThemeProvider
        value={{
          forceBlackAndWhite: experiments.enabled(
            'specs.groups.ForceBlackAndWhite',
          ),
          buttonType: settings.get(settingsParams.buttonType),
          buttonBorderRadius: styles.get(stylesParams.buttonBorderRadius),
        }}
      >
        <div data-hook="groups" className={st(classes.root, { mobile })}>
          <AppToasts t={t} {...toasts} />
          <div className={classes.header}>
            <Text
              data-hook={DATA_HOOKS.groupListTitle}
              tagName={headerTag || 'h1'}
              typography={TextTypography.largeTitle}
              className={st(classes.title, {
                headerAlignment: settings.get(settingsParams.headerAlignment),
              })}
            >
              {withTitle &&
                (groupListTitle == null
                  ? t('groups-web.settings.TextTab.groups.default')
                  : groupListTitle)}
            </Text>
            <CreateGroupButton
              priority={ButtonPriority.secondary}
              data-hook={DATA_HOOKS.createNewButton}
              onClick={this.openCreateGroupModal}
              className={classes.createNewButton}
              biOrigin="plus_btn"
            >
              {t('groups-web.btn.create-new')}
            </CreateGroupButton>
          </div>
          <GroupRequestsList />
          {withSearchSortingBox && (
            <SearchSortingBox
              onSearch={this.handleSearch}
              onSort={actions.sortGroups}
              initialSortSelectedId={groupsSortBy as string}
            />
          )}
          {super.render()}
        </div>
      </ThemeProvider>
    );
  }

  private readonly handleSearch = (value: string) => {
    const { actions, bi } = this.props;
    bi.report(
      groupsSearchForGroup({
        group_name: value,
        origin: 'top_panel_editbox',
        userEntry: BIUserEntry.SITE,
      }),
    );
    actions.searchGroups(value);
  };
}

const enhance = compose(
  withGroupsListContext,
  withUser,
  withGroupsActions,
  withAppToasts,
  withSettings,
  withStyles,
  withTPAConfig,
  withWebOut,
  withMemberInvites,
  withBi,
  withExperiments,
  withTranslation(),
);
export const Groups = enhance(
  GroupsComponent,
) as React.ComponentType<GroupsProps>;
