import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';

import { Dialog, DialogProps } from '../Dialog';
import { Button } from '../Button';
import { Theme, withTheme } from '../../context/theme';

export interface WebOutProps extends DialogProps {
  buttonLabelKey: string;
  inviteLink: string;
  loadMemberInvitesLink(): void;
}

// Can not wrap withMemberInvites here, because different context instances will be used in groups-app/group and common
class WebOutComponent extends React.Component<WebOutProps & Theme> {
  componentDidMount() {
    this.props.loadMemberInvitesLink();
  }

  render() {
    const {
      inviteLink,
      buttonLabelKey,
      loadMemberInvitesLink,
      forceBlackAndWhite,
      ...restProps
    } = this.props;
    return (
      <Dialog
        {...restProps}
        buttons={
          <Button
            bw={forceBlackAndWhite}
            priority={ButtonPriority.primary}
            href={inviteLink}
          >
            {buttonLabelKey}
          </Button>
        }
      />
    );
  }
}

export const WebOut = withTheme(
  WebOutComponent,
) as React.ComponentType<WebOutProps>;
