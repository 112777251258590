import React from 'react';

import { ButtonPriority, Text, TextTypography } from 'wix-ui-tpa';

import { st, classes } from './DeleteGroupRequestModal.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from 'common/components/Modal/Modal';
import { withTheme, Theme } from 'common/context/theme';
import { Button } from 'common/components/Button';

export interface DeclineGroupRequestModalProps {
  onConfirmGroupDeletion(e: any): void;
  onRequestClose(e: any): void;
}

export function DeleteGroupRequestModalComponent(
  props: WithTranslation & DeclineGroupRequestModalProps & Theme,
) {
  const {
    onRequestClose,
    onConfirmGroupDeletion,
    t,
    forceBlackAndWhite,
  } = props;

  return (
    <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
      <Modal.Header title={t('groups-web.modal.delete-group-request.title')} />
      <Text
        typography={TextTypography.runningText}
        className={classes.subtitle}
      >
        {t('groups-web.modal.delete-group-request.subtitle')}
      </Text>
      <Modal.Buttons>
        <Button
          bw={forceBlackAndWhite}
          priority={ButtonPriority.secondary}
          onClick={onRequestClose}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          bw={forceBlackAndWhite}
          type="submit"
          priority={ButtonPriority.primary}
          onClick={onConfirmGroupDeletion}
        >
          {t('groups-web.modal.decline-group.action.delete')}
        </Button>
      </Modal.Buttons>
    </div>
  );
}

export const DeleteGroupRequestModal = withTheme(
  withTranslation()(DeleteGroupRequestModalComponent),
) as React.ComponentType<DeclineGroupRequestModalProps>;
