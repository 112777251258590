import React from 'react';
import { GroupPrivacyLevel } from '@wix/social-groups-api';

import { GroupTypeSelect } from '../../../../../common/components/GroupTypeSelect';
import { Theme } from '../../../../../common/context/theme';

export interface GroupTypesProps extends Theme {
  selectedType: GroupPrivacyLevel;
  onSelect(option: any): void;
}

const translations = {
  [GroupPrivacyLevel.PUBLIC]: {
    value: 'groups-web.types.public.name',
    subtitle: 'groups-web.types.public.description',
  },
  [GroupPrivacyLevel.PRIVATE]: {
    value: 'groups-web.types.private.name',
    subtitle: 'groups-web.types.private.description',
  },
};

export const GroupTypesDropdown: React.FC<GroupTypesProps> = (props) => {
  const { onSelect, selectedType, forceBlackAndWhite } = props;

  const transformedSelectedType =
    selectedType === GroupPrivacyLevel.SECRET
      ? GroupPrivacyLevel.PRIVATE
      : selectedType;

  return (
    <GroupTypeSelect
      forceBlackAndWhite={forceBlackAndWhite}
      onSelect={onSelect}
      selectedType={transformedSelectedType}
      translationKeys={translations}
    />
  );
};
